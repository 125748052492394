
 .organize-section-1{
  margin-top: 70px;
 }
.service-partner-section-1 {
  margin-bottom: 120px;
  &.bg-1 {
    background: url("../../../public/assets/images/whoWeAre/bg1.png");
    background-size: cover;
  }
 

  &.bg-2 {
    background: url("../../../public/assets/images/whoWeAre/bg2.jpg");
    background-size: cover;
    .title{
      max-width: 524px;

    }
    .desc{
      max-width: 424px;
    }
    // background-position:right 200% center;
  }
  .flex-box{
    padding-top: 40px;
    padding-bottom: 40px;
  }

  & .left {
    width: 50%;
    margin: auto 0;
  }
  & .right {
    width: 50%;
  }
}
.service-partner-section-2 {
  flex-direction: row;
  align-items: center;
  margin-bottom: 50px;
  & .left {
    &.img-box{
      display: flex;
      flex-direction: row;
      gap:20px;
      width: 60%;
      img{
        height: 361px;
        border-radius: 12px;
      }
    }

    .text-1 {
      max-width: 390px;
    }
    .text-2 {
      max-width: 390px;
    }
  }
  .right {
    &.max-content-box{
      max-width: 390px;
      margin: 0 auto;
      text-align: left;
    }
    width: 55%;
    text-align: center;
  }
}

.who-we-wrapper {
  padding-top: 120px;
  padding-bottom: 140px;

  .container {
    position: relative;

    .float-text {
      position: absolute;
      top: -125px;
      width: 50%;

      // margin:0  auto;
      left: 160px;

      width: 469px;
      z-index: 9;
    }
  }
}

.who-we-flex-box {
  border-radius: 20px;
  background: #e6f3ff;
  padding-left: 102px;
  padding-right: 102px;
  height: 360px;
  align-items: center;
  // gap: 162px;
  .left {
    width: 391px;
    margin: 0 auto;
  }
  .right {
    text-align: center;
    width: 50%;
  }
}

.contact-us-bg {
  background: #e6f3ff;
  border-radius: 20px;
}
.contact-us-section {
  align-items: center;
  padding: 60px 68px;
  gap: 60px;

  & .right {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    padding: 40px;
    background: #fff;

    .row-input {
      width: calc(100% - 12px);
    }
  }
}

@media only screen and (max-width: 767px) {
  .contact-us-section {
    padding: 24px 0;
    gap: 38px;
    & .right {
      padding: 0;

      background: none;
      .row-input {
        width: 100%;
      }
    }
  }
  .organize-section-1{
    .ant-col{
      margin-bottom: 40px;
      & img{
        width: 100% !important;
        height: auto;
      }
    }
  }
}


.trusted-brands {
  padding-bottom: 120px;
  & .title {
    font-size: 20px;
  }
  & .brand-flex {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 80px;
    padding-top: 40px;
    & .brand-img {
      height: 70px;
      width: auto;
    }
  }
  @media only screen and (max-width: 991px) {
    padding-top: 40px;
    & .title {
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 767px) {
    padding-bottom: 40px;
    & .brand-flex {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
  }
}

// testimonials -- section responsive

@media only screen and (max-width: 575px) {
  .who-we-wrapper {
    & .left {
      width: 100%;
    }
  }

  //
  .service-partner-section-1 {
    & .left {
      padding-top: 20px;
      padding-bottom: 4px;
      text-align: center;
      & .fs-48 {
        font-size: 28px !important;
        line-height: 1.2 !important;
        padding-bottom: 8px !important;
      }
    }
    .flex-box{
      & .box-btn{
        margin-bottom:40px;
      }
      padding-top: 20px;
      padding-bottom: 0;
    }
    .box-btn{
      margin: 0 auto;
    }
  }
}


@media only screen and (max-width:1200px){
  .service-partner-section-2 {

    & .left {
      &.img-box{
        width: 100%;
        gap: 5%;
        & .img-1{
          width:55% ;
          height: auto;
        }
        & .img-2{
          width:40% ;
          height: auto;
        }
     
      }
    }
    & .right.max-content-box{
      padding-top: 20px;
   width: 100%;
   max-width: 100%;
    }
  }
}

.organize-grid{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 90px;
  margin-top: 10px;
}