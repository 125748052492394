.prospect-list-action-wrap {
    box-shadow: 0px 16px 32px rgba(20, 30, 73, 0.15);
    min-width: max-content;
.list-action-item {
    height: 34px;
    background: #fff;
    cursor: pointer;
    padding: 8px 12px;
    font-weight: 400;
    border-radius: 6px;
    min-width: max-content;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  
    &:hover {
      font-weight: 500;
    }
  }

}

