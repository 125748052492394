#table-container {
  overflow-x: auto;
  white-space: nowrap;
}

#table-container table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.8rem;
  overflow: hidden; /* Helps with rounded corners */
}

#table-container th, #table-container td {
  text-align: start; /* Align text to the start */
  padding: 8px;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

#table-container tr:nth-child(even) {
  background-color: #f2f2f2;
}

#table-container tr:hover {
  background-color: #ddd; /* Row hover effect */
}

#table-container .data-table-contain table td {
  font-size: 0.85rem;
  padding: 12px;
  line-height: 1.2;
  color: #212427;
  font-weight: 400;
  border-bottom: 1px solid #e2e5ea;
  text-align: start;
}
