.rounded-sm{
    border-radius: 4px !important;
}
.rounded-base{
    border-radius: 6px !important;
}
.rounded-lg{
    border-radius: 8px !important;
}
.rounded-xl{
    border-radius: 12px !important;
}
.rounded-xxl{
    border-radius: 16px !important;
}