.notification-container {
    width: 70%;
   margin: 15px;
    padding: 20px;
   
  }
  
  .notification-container .ant-select,
  .notification-container .ant-input {
    width: 100%;
  }
  
  .notification-container .ant-btn-primary {
    background-color: #1890ff;
    border-color: #1890ff;
    color: #fff;
    width: 100%;
    margin-top: 20px;
  }
  