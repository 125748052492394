#popover {
   
   
   
    max-width: 180px;
    padding: 0px;
    text-align: left;
    white-space: normal;
   
   
   
    -webkit-border-radius: 6px;
       -moz-border-radius: 6px;
            border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
       -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
       -moz-background-clip: padding;
            background-clip: padding-box;
  }

  #navreffer:hover{
    background-color: #0281ff20;
  }