.hide-nav-bottom-ink {
  .ant-tabs-nav {
    width: 100%;
    .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-ink-bar {
      background: none;
    }
  }
  .ant-tabs-content-holder {
    border-left: transparent !important;
  }
}
