.attachment-card{
    height: 300px !important;
    overflow: hidden;
    width: 100%;
    box-shadow: rgb(0 0 0 / 7%) 1px 0px 20px 20px;

   & .card-image{
        max-height: 300px !important;
        min-height: 300px !important;
        object-fit: cover !important;
        width: 100%;

    }
}