

.profile-wrap{
   padding: 36px 63px;
}


@media screen and (max-width:767px){

   .profile-wrap{

      padding: 20px 15px;
   }
   .profile-tab-bar{
      & .ant-tabs-nav{
         padding-left: 15px !important;

      }
   }
}

// booking detail
.steps-container {
   width: 600px !important;
   max-width: 100%;
   margin: 0 auto;
}

