.video-preview-modal{
    & .ant-modal-close{
        top: -44px;
    right: -9px;
    color: #fff;
    }
    & .ant-modal-content{
        border-radius: 12px;
    }
}

@media only screen and (max-width:767px){
    
    .video-preview-modal{
        & .ant-modal-close{
            top: -10px;
            color: #000;
            font-size: 20px;
        }  
    }
    .login-modal{
        max-width: 100% !important;
        margin: 0 !important;
        max-height: 100vh !important;
    }
}
@media only screen and (max-width:991px){
    .login-modal{
        max-width: 100% !important;
        margin: 0 !important;
        max-height: 100vh !important;
    }
}