@media only screen and (max-width: 991px) {
  .who-we-wrapper {
    padding-top: 50px;
    padding-bottom: 0;
    .container {
      background: #e6f3ff;

      .float-text {
        text-align: center;
        position: relative;
        padding-bottom: 20px;
        top: unset;
        width: 100%;
        padding-top: 24px;

        left: 0;
      }
    }
  }
  .who-we-flex-box {
    height: 100% !important;
    align-items: center;
    border-radius: 0;
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
    .left,.right{
      width: 100%;
    }
  }
  .contact-us-bg {
    background: #e6f3ff;
    border-radius: 0;
  }
}
