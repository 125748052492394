.service-menu ul {
    margin: 0px;
    display: flex;
    flex-direction: row;
    column-gap: 15px;
}

.link-user {
    /* font-weight: 500; */
    /* font-size: 16px; */
    line-height: 16px;
    color: #1E90FF;
    margin-bottom: 0px;
}

.dropdown-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    cursor: pointer;
}

.header-item {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    align-items: center;
}

.username-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 8px;
}

.name-text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #212427;
}

.img-item {
    background: #dbd8d8b0;
    padding: 8px;
    border-radius: 50%;
}

.dropdown-item-container {
    display: flex;
}
.login-container {
    width: 100% !important;

}
.singup-container {
    width: auto !important;
    position: absolute !important;
    top: 0px !important;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.input-container input {
    width: 100%;
}

.service-img img {
    zoom: 1.7;
}

.service-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

/* .services-list ul li span {
    font-size: 1.3rem;
    font-weight: 600;
}

.services-list h3 {
    font-size: 2.4rem;
} */

.icon-item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;
    justify-content: center;
}

.icon-item:hover {
    transform: scale(1.05);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}


.location-search .ant-select-auto-complete {
    width: 100% !important;
    height: 100%;
}

.location-search .ant-select-auto-complete .ant-select-selector {
    border: unset !important;
}

.top-banner .home-page-banner-search .banner-search .location-search input {
    height: auto !important;
    padding: 0px !important
}

.top-banner .home-page-banner-search .banner-search .location-search span {
    display: flex;
}

.about-us-container {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
}

.about-us-container .img-about {
    border-radius: 10px;
}

.para-desc {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.link-btn {
    background: #0081FF;
    border-radius: 6px;
    color: #ffff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 139px;
    cursor: pointer;
}

.link-btn p {
    margin: 10px 0px;
}

.chose-container {
    margin-top: 20px;
}

.para-desc .header {
    text-align: center;
}

.user-container .ant-popover-inner {
    border-radius: 8px;
}

.link-view-container {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.link-view {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #0081FF;
    cursor: pointer;
}

.link-view-per {
    font-weight: 500;
    font-size: 9px;
    line-height: 9px;
    text-decoration-line: underline;
    color: #8B8B8B;
}

.item-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    padding: 10px 10px;
    cursor: pointer;
    margin-bottom: 10px;
}

.item-container:hover {
    background: #EDF0F3;
}

.item-section-1 {
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 12px;
}

.link-item {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #212427;
}

.google-auto div:first-child {
    width: 100%;
    border: 0px;
}

.google-auto div:nth-child(2) {
    border: 0px;
}

.manger-header {
    flex: 1;
    justify-content: center;
}

.manger-header .menu-ul-container {
    column-gap: 60px;
}


.manger-header .menu-ul-container .header-item-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 9px;
}

.carousel .slide {
    justify-content: center;
    display: flex;
    align-items: center;
}

.testimonial .user-testimonial .testimonial-block {
    background: #fff;
    padding: 24px;
    width: 322px;
    border-radius: 12px;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    column-gap: 30px;
    height: 530px;
}

.user-info {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.user-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /*align-items: flex-start;*/
}


.testimonial .user-testimonial .testimonial-block .user-info img {
    width: 320px;
    height: 320px;
    border-radius: 5px;
    margin-right: unset;
}

.apps-area {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
}

.ant-popover-content {
    width: 300px !important;
    top: 8px;
}

.pop-cover-text span:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
}

.serv-banner-inner {
    height: 446px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.serv-banner-inner .banner-left-box {
    width: 600px !important;
}

.banner-right-box img.flex {
    height: 100%;
    object-fit: cover;
}

section.service-banner {
    margin-top: 30px;
}




.desc-container {
    height: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.item-container {
    width: 300px;
    border-radius: 7px !important;
    padding: 8px !important;
    display: flex;
    flex-direction: column;
}

.item-container:hover {
    background-color: #fff;
}

.item-main {
    height: 48px;
    border-radius: 6px;
    width: 100%;
}

.item-main .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 12px;
}

.item-main .detail-item {
    flex: 1;
}

.detail-item .detail-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 61px;
    color: #535353;
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    row-gap: 10px;
    column-gap: 10px;
    justify-content: space-around;
}

.grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    font-size: 30px;
    text-align: center;
}

.testimonial .user-testimonial .testimonial-block p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #212427;
    margin-top: 15px;
    /*overflow: hidden;*/
    text-overflow: ellipsis;
    margin-bottom: 0px;
}

.footer-networks {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: flex-start;*/
    /*margin-top: 0px;*/
    /*row-gap: 40px;*/
}

.grid-container-three {
    display: grid;
    grid-template-columns: auto auto auto;
    row-gap: 200px;
    column-gap: 10px;
    margin-bottom: 150px;
    justify-content: space-around;
}

.grid-container-three .lead-description{
    width: 130%;
}

#notificationHeader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

#notificationBody {
  margin-top: 10px;
  text-align: center;
}

#imageContainer {
  display: flex;
  align-items: center;
  height: 100px;
  object-fit: contain;
}