.ant-btn {
  height: 48px !important;
  &.h-40{
    height: 40px !important;
  }
  border-radius: 6px !important;
}

.light-bg {
  background: #deefff;
}
.btn-h-36 {
  height: 36px !important;
  line-height: 1;
}
.input-h-40 {
  border-radius: 6px !important;
  & .ant-input {
    height: 40px !important;
    border-radius: 6px !important;
  }
}
.input-h-46 {
  border-radius: 6px !important;
  & .ant-input {
    height: 46px !important;
    border-radius: 6px !important;
  }
  & .ant-input-number-input-wrap {
    height: 46px !important;
    border-radius: 6px !important;
    input {
      height: 46px !important;
    }
  }
}

.select-h-46 {
  border-radius: 6px !important;
  & .ant-select-selector {
    padding: 7px 11px !important;
    border-radius: 6px !important;
    height: 46px !important;
    & .ant-select-selection-placeholder {
      line-height: 46px !important;
    }
  }

}

.text-underline{
  text-decoration: underline !important;
}


.ant-picker.input-h-46{
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.ant-btn.btn-h-unset{
 height: unset !important;
}

// checkBox  group
#therapy_protocols label,#exercise_therapy label {
  font-size: 14px;
  width: 45%;
  margin-right: 20px;
  margin-bottom: 10px;
  @media only screen and (max-width:575px) {
    width: 95%;
  }
}