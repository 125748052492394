.help-support-collapse-2{
 .ant-collapse-header{
    background: #fff !important;
 }
 .ant-collapse-header{
    border-radius: 6px !important;
 }
    .ant-collapse-content{
        border-top: 0 !important;  
    }
}