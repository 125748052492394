 .patient-order-detail-drawer{
   
    & .ant-drawer-body {
padding: 30px;
    }

    & .ant-tag {
        width: 119px;
        height: 22px;
        border-radius: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border: none;
        &.Completed {
          background:  #55B670;
        }
        &.Pending {
          background: #f48224;
        }
        &.Cancelled {
          background: #ec7f78;
        }
      }

  &.status-drawer{
    & .ant-drawer-header-title{
      flex-direction: row-reverse;
    }

  }    
}
.ant-space.item-w-100{
& .ant-space-item{
  width: 100% !important;
}
}