.appointment-card {
  & .ant-card-body {
    padding: 10px 0 !important;
  }
  & .ant-tag {
    width: 119px;
    height: 22px;
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: none;
    &.Completed {
      background:  #55B670;
    }
    &.Pending {
      background: #f48224;
    }
    &.Cancelled {
      background: #ec7f78;
    }
  }
}
