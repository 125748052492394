.patient-detail-wrap {
  max-width: 1200px;
  margin: 20px auto;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}
.patient-detail-wrapnew {
  margin: 20px 0%;

  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}