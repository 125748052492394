@media only screen and (max-width:991px){
    .home-mobile-size-label{
   font-size: 24px !important;
    }
    .ant-btn{
        height: 40px !important;
    }
    .w-lg-100{
        width:100%  !important ;
    }
    .h-lg-100{
 height: 100% !important;
    }
}

@media only screen and (min-width:992) {
    .max-w-421{
        max-width: 421px;
    }
}