.fs-10 {
  font-size: 10px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-17 {
  font-size: 17px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-19 {
  font-size: 19px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-21 {
  font-size: 21px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-23 {
  font-size: 23px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-25 {
  font-size: 25px !important;
}
.fs-26 {
  font-size: 26px !important;
}
.fs-26 {
  font-size: 26px !important;
}
.fs-27 {
  font-size: 27px !important;
}
.fs-28 {
  font-size: 28px !important;
}
.fs-29 {
  font-size: 29px !important;
}
.fs-30 {
  font-size: 30px !important;
}
.fs-31 {
  font-size: 31px !important;
}
.fs-32 {
  font-size: 32px !important;
}
.fs-33 {
  font-size: 33px !important;
}
.fs-34 {
  font-size: 34px !important;
}
.fs-35 {
  font-size: 35px !important;
}
.fs-36 {
  font-size: 36px !important;
}
.fs-36 {
  font-size: 36px !important;
}
.fs-37 {
  font-size: 37px !important;
}
.fs-38 {
  font-size: 38px !important;
}
.fs-39 {
  font-size: 39px !important;
}
.fs-40 {
  font-size: 40px !important;
}
.fs-41 {
  font-size: 41px !important;
}
.fs-42 {
  font-size: 42px !important;
}
.fs-43 {
  font-size: 43px !important;
}
.fs-44 {
  font-size: 44px !important;
}
.fs-45 {
  font-size: 45px !important;
}
.fs-46 {
  font-size: 46px !important;
}
.fs-46 {
  font-size: 46px !important;
}
.fs-47 {
  font-size: 47px !important;
}
.fs-48 {
  font-size: 48px !important;
}
.fs-49 {
  font-size: 49px !important;
}
.fs-50 {
  font-size: 50px !important;
}

// font weight classes
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}

// font line height classes
.lh-1 {
  line-height: 1 !important;
}
.lh-0 {
  line-height: 0px !important;
}
.lh-10 {
  line-height: 10px !important;
}
.lh-11 {
  line-height: 11px !important;
}
.lh-12 {
  line-height: 12px !important;
}
.lh-13 {
  line-height: 13px !important;
}
.lh-14 {
  line-height: 14px !important;
}
.lh-15 {
  line-height: 15px !important;
}
.lh-16 {
  line-height: 16px !important;
}
.lh-17 {
  line-height: 17px !important;
}
.lh-18 {
  line-height: 18px !important;
}
.lh-19 {
  line-height: 19px !important;
}
.lh-20 {
  line-height: 20px !important;
}
.lh-21 {
  line-height: 21px !important;
}
.lh-22 {
  line-height: 22px !important;
}
.lh-23 {
  line-height: 23px !important;
}
.lh-24 {
  line-height: 24px !important;
}
.lh-25 {
  line-height: 25px !important;
}
.lh-26 {
  line-height: 26px !important;
}
.lh-27 {
  line-height: 27px !important;
}
.lh-28 {
  line-height: 28px !important;
}
.lh-29 {
  line-height: 29px !important;
}
.lh-30 {
  line-height: 30px !important;
}
.lh-31 {
  line-height: 31px !important;
}
.lh-32 {
  line-height: 32px !important;
}
.lh-33 {
  line-height: 33px !important;
}
.lh-34 {
  line-height: 34px !important;
}
.lh-35 {
  line-height: 35px !important;
}
.lh-36 {
  line-height: 36px !important;
}
.lh-37 {
  line-height: 37px !important;
}
.lh-38 {
  line-height: 38px !important;
}
.lh-39 {
  line-height: 39px !important;
}
.lh-40 {
  line-height: 40px !important;
}

.lh-57{
  line-height: 57px !important;
}
.lh-1{
  line-height: 1 !important;
}
.lh-1-1{
  line-height: 1.1 !important;
}
.lh-1-2{
  line-height: 1.2 !important;
}
.lh-1-3{
  line-height: 1.3 !important;
}
.lh-1-4{
  line-height: 1.4 !important;
}
// cursors- classs
.cursor-auto {
  cursor: auto !important;
}
.cursor-default {
  cursor: default !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-wait {
  cursor: wait !important;
}
.cursor-text {
  cursor: text !important;
}
.cursor-move {
  cursor: move !important;
}
.cursor-help {
  cursor: help !important;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}

.mr-1{
  margin-right: 1rem !important;
}
.mr-2{
  margin-right: 2rem !important;
}
.mr-3{
  margin-right: 3rem !important;
}
.ml-1{
  margin-left: 1rem !important;
}
.ml-2{
  margin-left: 2rem !important;
}
.ml-3{
  margin-left: 3rem !important;
}
.mt-1{
  margin-top: 1rem !important;
}
.mt-2{
  margin-top: 2rem !important;
}
.mt-3{
  margin-top: 3rem !important;
}
.mb-1{
  margin-bottom: 1rem !important;
}
.mb-2{
  margin-bottom: 2rem !important;
}
.mb-3{
  margin-bottom: 3rem !important;
}

.w-max-content{
  width: max-content !important;
}