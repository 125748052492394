$primary: #0081FF !default;
$hoverPrimary:#2C45CA ;
// $color-primary-light: #FFF !default;
// $color-primary-dark: #000 !default;
// $color-primary-dark: #000 !default;


.text-underline{
    text-decoration: underline !important;
    color:#0081FF;
    
}
.text-primary{
    color: #0081FF !important;
}
.border-grey-10{
    border:1px solid #E2E5EA !important;
}
.border-primary{
 border: 1px solid #0081FF !important;   
}

.text-black{
  color:  #212427 !important;
}
.hover-bg-primary-light:hover{
    transition: all ease-in-out 0.3s;
    background: #0080ff3e;
}
.hover-text-primary{
  &:hover{
    color: #0081FF !important;
  }

}
//  background color classes
.bg-none{
  background: none !important;
}
.bg-primary-light{
  background: #EDF6FF !important;
}
//  background color classes

// btn colors
.ant-btn{
  &.h-40{
    height: 40px !important;
  }
    &.ant-btn-default{
        border-color: $primary !important;
        color: $primary !important;
        &:hover{
background: #DEEFFF;
          border-color: $primary !important;
          color: $primary !important;
        }
      
      }

      &.ant-btn-primary{
        border-color: $primary !important;
        background: $primary !important;
        &:hover{
          border-color: $hoverPrimary !important;
          background: $hoverPrimary !important;
        }
      }
}

.text-primary-light{
  color: #323659;

}

.text-grey{
  color: #6B6D83;
}