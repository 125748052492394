  #conference-form-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  #conference-logo {
    width: 190px;
    margin-bottom: 20px;
  }
  
  #conference-title {
    text-align: center;
    color: #1890ff;
  }
  
  #conference-form-card {
    margin-top: 20px;
    width: 100%;
  }
  
  
   

  #conference-modal-text {
    font-size: 14px; 
    text-align: center;
  }  



  /* @media (max-width: 768px) {
    .ant-modal {
      width: 90% !important;
      margin: 0 auto;
    }
  }
  
 
  
  /* .ant-modal {
    right: 4% !important;
    
  }  */