@media (max-width: 768px) {
    /* For tablets and smaller screens */
    
    .datatable-list-head {
      flex-direction: column;
     
    }
  
    .data-table-contain {
      overflow-x: auto;
    }
  
    .datatable-list-head-title {
      margin-bottom: 10px;
    }
  
    .dd-flex {
      flex-direction: column;
      gap: 15px;
    }
  
    .booking-process {
      padding: 10px;
    }
  
    .p-3 {
      padding: 5px;
    }
  
    .cursor-pointer {
      width: 100%;
      justify-content: center;
    }
  }
  
  @media (max-width: 480px) {
    /* For mobile devices */
  
    .datatable-list-head2 {
      flex-direction: column;
    }
  
    .data-table-main-box {
      padding: 0 10px;
    }
  
    .datatable-list-head-title {
      font-size: 14px;
    }
  
    .p-3 {
      padding: 2px;
    }
  
    .data-table-contain {
      overflow-x: auto;
    }
  
    /* .ant-input {
      width: 100%;
    }
  
    .ant-btn {
      width: 100%;
    } */
     
  }
  