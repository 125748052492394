.product-slider {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 30px;
}
.product-slider.nursing-care{
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3,1fr) ;
    gap: 20px;
}
.product-slider.nursing-care .product-box-info {
  width: 100% !important;
}
.product-desc p {
    height: 93px;
    overflow-y: hidden;
    text-overflow: ellipsis;
}

.about-container {
    display: flex;
    flex-direction: row;
}

.about-us-container {
    display: flex;
}

.about-left {
    background-color: unset;
}

.about-left .align-items-center {
    background: unset;
    margin-top: 50px;
    display: flex;
    flex: 1;
    justify-content: space-between;
}

.about-left .banner-right-box {
    flex: 0.8
}

.container-why-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.why-section .heading {
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 64px;
    /* identical to box height, or 160% */
    color: #000000;
}

.why-section .item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between !important;
    width: 100px;
    height: 200px;
}

.img-about-team {
    height: 150px;
    border-radius: 10px;
}

.why-section .img-about {
    width: 120px;
    height: 120px;
}

.why-section .item-text {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #212427;
}


    .why-section .item-parent-container {
   display: grid;
   grid-template-columns: repeat(3,1fr);
   gap: 20px;
    width: 100%;
    margin-top: 30px;
}
.why-section .item-parent-container.partners {
    display: grid;
    grid-template-columns: repeat(4,1fr) ;
    gap: 20px;
     width: 100%;
     margin-top: 30px;
 }
 .why-section .item-parent-container.partners img{
    height: 100px;
 }
.why-section .item-parent-container .item-container{
    width: 100%;
}

.grid-item {
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 20px;
}

.grid-item .grids-section-first {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.item-team-container {
    justify-content: flex-start !important;
}

.team-name-container {
    display: flex;
}

.date-picker {
    padding: 0.375rem 1rem;
    font-size: 1rem;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #E2E5EA;
    -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    display: block;
    position: relative;
    border-radius: 6px;
    height: 48px;
}

.about-paragraph {
    width: 85%;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212427;
}

.italic {
    font-style: italic;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #0066BA;
}