.emoji-container {
    display: flex;
    justify-content: space-between;
    width: 200px;
  }
  
  .emoji-button {
    margin: 5px;
    border: none !important;
    font-size: 20px;
    transition: transform 0.3s ease-in-out;
    background: none;
    outline: none;
  }
  
  .emoji-button:hover {
    transform: scale(1.3);
    cursor: pointer;
  }
  
  .emoji-button.selected {
    margin: 5px;
    color: #1890ff;
    transform: scale(1.4);
    background-color: #e6f7ff;
  }
  


  /* styles.css */
/* styles.css */

.image-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 16px;
 
  border-radius: 10px;
 
}

.image-button {
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.image-button:hover {
  transform: scale(1.3);
}

.image-button.selected {
  transform: scale(1.8);
}

.emoji-image {
  width: 30px;
  height: 30px;
}



/* styles.css */

/* styles.css */

/* styles.css */

.rating-wrapper {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

.rating-labels {
  display: flex;
  justify-content: space-between;
  color: gray;
  margin-bottom: 10px;
}

.rating-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rating-button {
  background: none;
  border: 1px solid #333;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.rating-button:hover {
  transform: scale(1.1);
}

.rating-button.selected {
  background-color: #007bff;
  color: #fff;
  transform: scale(1.3);
}
