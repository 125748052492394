.header-chat-icon {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background: #afafaf2e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-popover {
  & .ant-popover-inner {
    border-radius: 12px;
  }
  & .ant-popover-inner-content {
    padding: 4px;

    width: 100%;
  }
}
.chat-list-box {
  max-height: 300px;
  overflow-y: auto;
  border-radius: 0;
  width: 100%;
  & .chat-list-item {
    width: 100%;
    height: 60px;
    transition: all ease-in 0.3s;
    display: flex;
    align-items: center;
    padding-left: 20px;
    cursor: pointer;
    &:hover {
      background: #0080ff50;
    }
    border-bottom: 1px solid #b4b3b37d;
    &:last-child {
      border-bottom: none;
    }
  }
}

.float-chat-wrapper {
  height: 600px;
  max-height: 90vh;
  z-index: 999999999;
  width: 450px;
  position: fixed;
  right: 10px;
  bottom: 10px;
  box-shadow: 0px 0px 32px #61606091;
}

// public nabvar
.sticky-navbar-wrapper {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 999;
  border: 1px solid #fff;
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 170.14%);
  box-shadow: 0px 4px 20px 0px rgba(50, 54, 89, 0.08);
  backdrop-filter: blur(6px);
}
.public-navbar {
  display: flex;
  justify-items: space-between;
  align-items: center;
  height: 72px;
  &.right-options {
    display: flex;
    align-items: center;
  }

  .nav-burger {
    margin-left: 18px;
  }
}

.nav-drawer {

  & .ant-collapse-header{

    padding-left: 0 !important;

  }
  & .ant-drawer-header{
    justify-content: end ;
    & .ant-drawer-close{
      margin-right: 0;
    }
  }
  & .ant-collapse-content-box{
    padding: 0 !important;
    & .item{
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
  & .ant-drawer-body{
    padding-top: 0 ;
  }
  & .ant-drawer-header {
    border-bottom: none;
    & .ant-drawer-header-title {
      flex: unset !important;
      & .ant-drawer-close {
        cursor: pointer;
        & .ant-drawer-close {
          scale: 1.5;
          & svg {
            fill: #000;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .public-nav-logo {
    height: 40px;
  }
}

.who-we-are-popover{
  & .ant-popover-content{
    max-width: 240px;
    width:240px;
    border-radius: 8px;overflow: hidden;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(3, 63, 122, 0.12);
  }
}
.who-we-are-wrapper {
  width: 208px;
 & .ant-divider{
  stroke-width: 1px;
stroke:  #F2F2F8;
margin-top: 4px;
margin-bottom: 4px;
@media only  screen and (max-width:991px) {
  display: none !important; 
}
 }
  // width: 240px;
  & .item {
    height: 34px;
    width: 100%;
    padding: 4px 10px;
    display: flex;
    align-items: center;
    color: #242424;
    &:hover,&.active {
      border-radius: 4px;
      background: #e6f3ff;
      color: #0081ff;
    }
  }
}


@media screen and (max-width: 450px) {
  .public-nav-logo {
    height: 28px;
  
  }
  .public-navbar{
    & .req-demo-btn{
      height: 36px !important;
    }
  }

}