@import "../../node_modules/@chatscope/chat-ui-kit-styles/themes/default/variables";
@import "../../node_modules/@chatscope/chat-ui-kit-styles/themes/default/main";

$color-primary: #000 !default;
$color-primary-light: #FFF !default;
$color-primary-dark: #000 !default;
$color-primary-dark: #000 !default;

.cs-message--outgoing {

& .cs-message__content{
    background-color:#0081FF !important ;
    color: #fff !important;
}
} 

.cs-message--incoming {

    & .cs-message__content{
        background-color:#0080ff40 !important ;
     
    }
    } 
    .cs-message-input__content-editor-wrapper{
        background-color:#0080ff40 !important ;
    }   
    .cs-message-input__content-editor-wrapper:first-child{
        min-height: 45px !important;
    }
    .cs-button--send{
        color:#0081FF !important ;
    } 

 .cs-conversation-header{
    height: 60px;
 }   