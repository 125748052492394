
  
 .pdf-viewer-modal{
    width: 100%;
    max-width: 100%;
    height: 100vh !important;
    top: 0 !important;

    & .cross-btn{
        height: 24px !important;
        margin-bottom: 0;
        display: flex;
        align-items: center;
    }
    & .ant-modal-content{
        height: 100vh;
    }
    & .ant-modal-body{
        height: calc(100% - 57px);
        overflow-y: auto;
    }
 }

 #pdf-view{
    position: relative;
     
    & .client-buttonc {
        display: flex;
     align-items: center;
      left: 50%;
      right: 50%;
        justify-content: center;
        position: fixed;
        bottom: 15px;
        gap: 15px;
        & button{
            height: 40px;
            width: 40px;
        }
  
        & .pagec {
          margin: 0 12px;
        }

        & button {
          background: #000;
          display: flex;
          border: none;
          font-size: 1.4rem;
          align-items: center;
          width: 28px;
          height: 28px;
          line-height: 12px;
          cursor: pointer;
          color:#fff;
  
          &:disabled {
            opacity: 0.5 !important;
            cursor: not-allowed;
            transform: scale(1) !important;
          }
  
          &:hover {
            background: #0081FF;
          }
          &.add-e-sign-btn{
    
              font-size: 12px;
              display: flex;
              align-items: center;
              min-width:140px  !important;
             }
        }
      }


 }
  
  .client-main {

    display: flex;
    justify-content: center;
    // background: white;
    align-items: center;
  
    & .react-pdf__Page__annotations {
      display: none;
    }
  
    & .react-pdf__Document {

      min-width: 50%;

      text-align: center;
      
      justify-content: center;
      align-items: center;
    }
  
    &:hover {
      .client-pdf-viewer-page-option {
        transition: 0.3s ease-in;
        opacity: 1 !important;
      }
  
      .client-toggle-btn {
        transition: 0.3s ease-in;
        opacity: 1 !important;
      }
    }
  

  
    & .client-pdf-viewer-page-option {
      transition: 0.3s ease-in;
      opacity: 0;
      position: fixed;
      bottom: 15px;
      left: 50%;
      transform: translate(-50%, -50%) scale(1.2);
      background: white;
      border-radius: 5px;
      box-shadow: 1px 1px 19px 13px rgb(0 0 0 / 25%);
      overflow: hidden;
 
  
      & .client-pagec {
  
        & input::-webkit-outer-spin-button,
        & input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
  
        & input[type=number] {
          width: 38px;
          height: 20px;
          text-align: center;
          border: 1px solid #6c6c6c;
          -moz-appearance: textfield;
          border-radius: 2px;
        }
      }
    }
  
    &:-webkit-full-screen,
    &:-moz-full-screen,
    &:-ms-fullscreen,
    &:fullscreen {
      width: 100vw;
      height: 100vh;
  
      & {
  
        .client-pdf-viewer-page-option,
        .client-toggle-btn {
          position: fixed;
        }
      }
    }
  
    & .client-page-canv {
      & .react-pdf__Page__canvas {
        max-width: 100%;
        margin: auto;
      }
    }
  }
  
  .client_pdf__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
    padding: 10px;
  }
  
  .client_pdf__container__load {
    margin-top: 1em;
    color: white;
  }
  
  .client_pdf__container__document {

    height: 100%;
    overflow-y: auto;
  }
  
  .client_pdf__container__document .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .client_pdf__container__document .react-pdf__Page {
    max-width: calc(100% - 2em);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    margin: 1em;
  }
  
  .client_pdf__container__document .react-pdf__Page canvas {
    max-width: 100%;
    height: auto !important;
  }
  
  .client_pdf__container__document .react-pdf__message {
    padding: 20px;
    color: white;
  }
  
  .scaler {
    position: fixed;
    left: 20px;
    bottom: 10%;
    display: flex;
    flex-direction: column;
  
    & button {
      border: 0;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 3rem;
      margin-bottom: 5px;
      color: #fff;
      background-color: #000;
      cursor: pointer;
  
      &:disabled {
        opacity: 0.5 !important;
        cursor: not-allowed;
        transform: scale(1) !important;
      }
  
      &:hover {
        opacity: 0.7;
        transform: scale(1.1);
        transition: scale 0.3 ease-in;
      }
    }
  }
  
  .file-viewer-drawer {
    & .ant-drawer-content-wrapper {
      & .ant-drawer-content {
        & .ant-drawer-wrapper-body {
          & .ant-drawer-header {
            & .ant-drawer-header-title {
              display: flex;
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }
  
  .file-viewer-title-body {
  
  display: flex;
  justify-content: space-between;
  }