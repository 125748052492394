.user-testimonial{

    & .slick-arrow::before{
    display: none !important;
    }

    & .slick-arrow::after{
        display: none !important;
        }
    & .slick-next:focus, .slick-next:hover, .slick-prev:focus{
        color: #000;
    }
    .slick-slide{
        opacity: 0.4;
        &.slick-current{
            opacity: 1;
        }
    }

}
