.ant-select-selector {
    height: 48px !important; 
    line-height: 48px !important;
  }
  
  .ant-select-selection-placeholder {
    /* line-height: 48px !important;  */
    

  }
  
  .ant-select-selection-item {
      align-items: center;
      justify-content: center;
      align-content: center;
  }