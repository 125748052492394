body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
  }
  
  #release-notes {
    width: 80%;
    margin: auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  #release {
    border-top: 1px solid #ddd;
    padding: 20px;
  }
  
  h1 {
    text-align: center;
    color: #333;
  }
  
  h2 {
    color: #666;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }
  
  h3 {
    color: #888;
    margin-top: 20px;
  }
  
  p {
    line-height: 1.6;
  }
  