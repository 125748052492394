@media only screen and (max-width: 1200px) {
  .container {
    max-width: 100% !important;
  }
}



.banner-wrapper {
  background: #d2dce74d;

  & .banner-content-section {
    display: flex;
    align-items: start;
    justify-content: space-between;

    .left-,
    .right- {
      width: 45%;
    }

    & .left- {
      padding-top: 75px;
      padding-bottom: 60px;
      max-width: 470px;

      & h3 {
        color: #323659;
        font-size: 60px;
        font-weight: 400;
        line-height: 1.2;
      }
    }

    & .right- {
      margin-top: auto;

      & .image-group-wrap {
        width: auto;
        height: 100%;

        & img {
          height: auto;
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width:767px) {
  .health-system-graph {

    background-image: url('../../assets/images/banner/map-mobile-responsive-bg.png') !important;
  }
}

.health-system-graph {
  width: 100%;
  position: relative;
  background-image: url('../../assets/images/banner/banner-graph-only.png');
  height: 550px;

  background-size: cover;
  background-repeat: no-repeat;

  & .container {
    position: relative;

    & .graph-lines {
      width: 100%;
      height: 390px;
      // margin-top: 80px;
      top: 80px;
      position: absolute;
    }

    & .graph-avatars {
      width: 100%;
      height: 500px;
      // margin-top: 80px;
      position: absolute;
      top: 50px;
      z-index: 3;
    }
  }
}

.health-care-section {
  padding-top: 90px;
  padding-bottom: 100px;

  & .content-flex-box {
    display: flex;
    flex-direction: row !important;
    align-items: center;
  }

  .left-- {
    width: 55%;
  }

  .right-- {
    width: 45%;
  }

  .right-- {
    display: flex;
    justify-content: end;
  }

  .health-care-image-wrapper {
    display: flex;
    align-items: center;
     gap: 16px;
     & >div{
      gap: 16px;
      display: flex;
      flex-direction: column;
      &:nth-child(1){
        align-items: flex-end;
      }
     }
    & .item {
      &.item-3 {
       
        width: 340px;
      }

      &.item-1 {
        
        width: 304px;
       
      }

      &.item-2 {
        width: 208px;
      }

      &.item-4 {
        width: 232px;
 
      }
    }
  }
}

// coordination section
.coordination-wrapper {
  margin-top: 146px;
  margin-bottom: 67px;
  background: linear-gradient(177deg,
      #eef2f6 2.78%,
      rgba(242, 245, 248, 0) 88.22%);

  & .content-flex-box {
    justify-content: center;
  }

  & .left {
    padding-top: 60px;
  }

  & .right {
    padding-left: 30px;

    & .coordination-img {
      position: relative;
      top: -86px;
      width: 506px;
    }
  }
}

.payors-bg {
  margin-top: 120px;
  margin-bottom: 86px;

  border-radius: 20px;

  & .content-flex-box {
    padding: 70px 90px;
  }

  background: linear-gradient(179deg, #f1f5f8 0%, rgba(242, 245, 248, 0) 100%);
  mix-blend-mode: multiply;
}

//  common

.banner-text-box {
  max-width: 421px;
}

.content-flex-box {
  display: flex;
  justify-content: space-between;
}

.patient-at-home-wrapper {
  & .content-flex-box {
    justify-content: unset;
    gap: 120px;
  }

  & .top-row {
    gap: 16px;

    img {
      width: calc(50% - 8px);
    }
  }
}

// footer style starts here

footer {
  background: #f1f5f8;
  padding-top: 28px;
  padding-bottom: 37px;
  padding-bottom: 36px;

  & .footer-links {
    gap: 76px;
    display: flex;

    & label {
      font-size: 14px;
      font-weight: 400;
      line-height: 32px;
      color: #323659;
    }

    & ul {
      list-style: none;

      padding-left: 0;

      li {
        font-size: 14px;
        font-weight: 500;
        line-height: 32px;
        color: #323659;
      }
    }
  }

  & .footer-social-links {
    display: flex;
    align-items: start;
    gap: 20px;

    & a:nth-child(2) {
      margin-top: -4px;
    }
  }
}

.video-react .video-react-big-play-button.video-react-big-play-button-center {
  display: flex;
  align-items: center;
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

.video-react .video-react-big-play-button.big-play-button-hide {
  display: none !important;
}

.video-react .video-react-big-play-button:before {
  top: 10px;
}

.video-react-controls-enabled {
  border-radius: 12px;
  overflow: hidden;
}

.home-stat-section {
  max-width: 100%;
  margin-bottom: 120px;

  & .stats-grid {
    display: flex;
    gap: 110px;
    margin-top: 40px;
    max-width: 916px;

    .row-1,
    .row-2 {
      width: 50%;
      gap: 90px;
      display: flex;
      justify-content: space-between;

      & .stat-grid-item {
        text-align: center;
        min-width: 134px;

        .text-1 {
          margin-top: 12px;
          font-size: 36px;
          color: #323659;
          font-weight: 400;
        }

        .text-2 {
          color: #6b6d83;

          font-size: 14px;
          margin-top: 2px;
          font-weight: 400;
        }
      }
    }

    .row-1 {
      & .stat-grid-item {
        &:first-child {

          .text-1,
          .text-2 {
            margin-top: 0;
            transform: translateY(-10px);
          }
        }
      }
    }
  }
}

//  responsive
@media only screen and (max-width: 1350px) {
  .banner-wrapper {
    .banner-content-section {
      .right- {
        .image-group-wrap {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .banner-wrapper {
    .banner-content-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .left-,
      .right- {
        width: 100%;
        text-align: center;

        .image-group-wrap {
          margin: 0 auto;
        }
      }

      .left- {
        padding-top: 20px;
        padding-bottom: 32px;
        max-width: 100%;
      }
    }
  }

  //

  .health-care-section {
    padding-top: 30px;
    padding-bottom: 30px;

    & .content-flex-box {
      flex-direction: column !important;
      text-align: center;

      & .left--,
      .right-- {
        width: 100%;
        max-width: 100%;
        text-align: left;
      }
    }
  }
}

// 1154
@media only screen and (max-width: 767px) {
  .payors-bg {
    margin-top: 60px;
    margin-bottom: 0;
  }


}


// footer
@media only screen and (max-width: 991px) {
  footer {
    & .footer-content-box {
      & .logo-section {
        margin-bottom: 20px;

        & img {
          height: 40px;
        }
      }

      & .footer-links {
        width: 100%;
        gap: 20px;
        justify-content: space-between;
        margin-bottom: 20px;
      }
    }

    & .footer-bottom-content {
      text-align: center;
      margin: 0 auto;
    }

    & .footer-social-links {
      text-align: center;
      justify-content: center;
      margin-bottom: 20px;
    }

    & .footer-bottom-content {
      justify-content: center !important;

      & .text-primary-light {
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        margin: 0 auto;

        &:nth-child(1) {
          margin-bottom: 30px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .health-system-graph .container .graph-avatars {
    width: calc(100% - 30px);
  }

  .health-system-graph .container .graph-lines {
    width: calc(100% - 30px);
  }
}

@media only screen and (max-width: 575px) {
  .footer-links {
    &>div {
      width: 45%;
    }
  }

  //  banner section -
  .banner-wrapper {
    .banner-content-section {
      .right- {
        .image-group-wrap {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .patient-at-home-wrapper {
    & .content-flex-box {
      gap: 0;
    }
  }

  .quality-wrapper {
    & .content-flex-box {
      flex-direction: column-reverse;
      padding: 48px 16px;

      & .right {
        width: 100%;

        img {
          width: 100%;
        }
      }

      & .left {
        width: 100%;

        &.banner-text-box {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }

  //  coordination-wrappe
  .coordination-wrapper {
    margin-top: 0;
    margin-bottom: 0;

    & .content-flex-box {
      & .right {
        padding-left: 0;
      }

      & .left {
        padding-top: 20px;
      }

      flex-direction: column-reverse;
      padding: 48px 16px;

      & .coordination-img {
        width: 100%;
        top: 0;
      }
    }
  }

  //  patient-at-home-wrapper

  .patient-at-home-wrapper {
    & .content-flex-box {
      flex-direction: column;

      & .left {
        width: 100%;

        & .top-row {
          max-width: 100%;
          gap: 16px;

          & img {
            width: calc(50% - 8px);
            margin-right: 0 !important;
          }
        }

        & .bottom-img {
          margin-bottom: 20px;
        }
      }

      & .right {
        width: 100%;
        max-width: 100%;

        p {
          margin-bottom: 20px !important;
        }
      }
    }
  }
}

//

@media only screen and (max-width: 1100px) {
  .health-care-section {
    .health-care-image-wrapper {
      & .item {
        &.item-3 {
          margin-right: 16px;
          width: 290px;
        }

        &.item-1 {
          width: 254px;
        }

        &.item-2 {
          width: 158px;
        }

        &.item-4 {
          width: 182px;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .health-care-section {
    & .content-flex-box {
      flex-direction: column;

      & .left-- {
        width: 100%;
          
        & .lg-box {
          gap: 16px;
          margin-bottom: 16px;

          img {
            width: calc(50% - 8px) !important;
          }
        }
      }
    }

    & .right-- {
      width: 100%;
      margin-top: 20px;

      & .fs-44,
      & p {
        max-width: 100% !important;
      }
    }

    .health-care-image-wrapper {
      display: flex;

      & .left-- {
        width: 100%;
      }

      & .item {
        &.item-3 {
          margin-right: 0;
          width: 100%;
        }

        &.item-1 {
          display: none;
        }

        &.item-2 {
          order: 1;
          width: 50%;
          height: 150px;
          display: none;
        }

        &.item-4 {
          order: 2;
          width: 50%;
          margin-top: 0;
          height: 150px;
          display: none;
        }
      }
    }
  }
}

// stats responsive
@media only screen and (max-width: 991px) {
  .home-stat-section {
    margin-bottom: 50px;

    & .stats-grid {
      display: flex;

      flex-direction: column;
      gap: 20px;
      margin-top: 40px;
      // max-width: 916px;

      .row-1,
      .row-2 {
        width: 100%;

        display: flex;
        justify-content: center;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .home-stat-section {
    & .stats-grid {

      .row-1,
      .row-2 {
        width: 100%;

        display: flex;
        justify-content: center;

        & .stat-grid-item {
          text-align: center;
          min-width: 134px;

          .text-1 {
            margin-top: 8px;
            font-size: 20px;
          }

          .text-2 {
            color: #6b6d83;

            font-size: 12px;
            margin-top: 2px;
            font-weight: 400;
          }
        }
      }
    }
  }
}


@media only screen and (max-width:575px) {

  .payors-bg {
    margin-top: 30px;
  }
}


//  testimonials 

.testimonial-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 45px;

  & .testimonial-item-box {
    cursor: pointer;
    position: relative;
    img {
      border-radius: 8px;
    }
    .content {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 12px;


    }
  }
}

@media only screen and (max-width:991px) {
  .testimonial-wrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
}

@media only screen and (max-width:600px) {
  .testimonial-wrapper {
    grid-template-columns: repeat(1, 1fr);

  }
}